@import "assets/highchart.css";

/* You can add global styles to this filename, and also import other style files */

html, body {
    height: 100%;
    background-color: var(--co-00, #0f1416);
}

.header {
    top: 0;
    left: 120px;
    padding-right: 132px;
    padding-left: 132px;
    width: calc(100% - 252px);
    color: #DEE8ED;
    line-height: 24px;
}

.header h1,
.header h6 {
    font-size: xx-large;
    display: table-cell;
    vertical-align: middle;
    height: 64px;
}

.header h1 {
    padding-right: 16px;
    white-space: nowrap;
}

/*.header-fixed {
    position: fixed;
    top: 0;
    left: 120px;
    padding-right: 132px;
    padding-left: 132px;
    width: calc(100% - 252px);
    color: #DEE8ED;
    line-height: 24px;
}*/

.fa1-upload-container {
    position: relative;
    height: 3em;
    display: flex;
    align-items: center;
}

.error {
    font-size: var(--16px);
    color: var(--co-primary);
    font-weight: 600;
}

app-nav {
    display: block;
    max-height: calc(100vh - var(--top-bar-height));
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    overflow-x: hidden;
}

app-nav::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

table {
    width: 100%;
}

.mat-mdc-tooltip.tooltip {
    font-size: 16px;
}

.mat-mdc-progress-bar {
    width: 100px;
}

.mat-mdc-form-field {
    font-family: inherit;
}

.mat-icon {
    color: var(--co-55)
}

app-icon {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.g-head {
    padding: var(--padding-content);
    background-image: linear-gradient(135deg, var(--co-10), var(--co-25-transparent));
}

.g-head.g-hero {
    padding: var(--padding-double) var(--padding-content);
    margin-bottom: var(--padding-content);
    align-items: center;
}

@media screen and (max-width: 768px) {
    .g-head {
        padding: 2em;
    }
}

.g-head.g-form {
    display: flex;
    flex-wrap: wrap;
    padding-top: var(--padding);
    padding-bottom: var(--padding);
}

.g-head.g-form .g-headline {
    margin: 0 var(--padding-content) var(--padding-double) 0;
}

.g-head.g-form form {
    display: inline-block;
    text-align: right;
}

.g-head.g-form .mat-mdc-form-field {
    min-width: 300px;
    margin: calc(var(--padding-half) / 2) 0;
}

.g-sub-headline {
    color: var(--co-25);
    padding: var(--padding-double) 0 var(--padding) var(--padding-double);
}

.g-status {
    text-align: left;
}

.g-status.warn {
    color: var(--co-primary);
}

.g-form-main-button {
    margin: 2em;
}

.g-form-login-help {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.g-form-pw-forgotten {
    margin: 2em;
}

.g-form-registration-link {
    margin: 2em;
}

.g-form-login-link {
    margin-bottom: 1em;
}

.g-button, .g-button-primary {
    color: var(--co-100);
    border: 1px solid;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: bold;
    padding: .7em 2em;
    cursor: pointer;
}

.date-ranges {
    display: flex;
    justify-content: space-between;
    text-align: center;
    max-width: 256px;
}

.datepicker-button {
    color: var(--co-55);
    background: transparent;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 1;
    font-size: 1.20em;
    border-bottom: 1px solid;
}

.datepicker-button:hover {
    color: var(--co-100);
}

.g-button-primary {
    color: var(--co-primary);
}

.g-button.g-disabled {
    color: var(--co-55);
    cursor: default;
    cursor: not-allowed;
}

.g-container {
    padding: var(--padding-content);
}

.g-hero {
    display: flex;
}

.g-hero-text {
    display: flex;
    flex: 0 0 auto;
    flex-basis: 20em;
    padding-left: var(--padding-content);
}

.g-head.g-hero .g-hero-text {
    padding: 0 var(--padding-content) 0 0;
}

@media screen and (max-width: 768px) {
    .g-hero-text {
        flex-basis: auto;
    }

    .g-head.g-hero .g-hero-text {
        padding: var(--padding)
    }
}

.g-container .g-hero-text {
    padding: 0;
}

.g-hero-text-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--padding-double) 0 calc(var(--padding) * 1.2) 0;
}

.g-hero-text-headline {
    margin: var(--padding-double) 0 var(--padding-content) var(--padding-half)
}

.g-hero-text-headline img {
    width: 9em;
}

.g-hero.no-img .g-hero-text {
    flex-basis: auto;
    margin: var(--padding-content) 0 0;
}

.g-hero-img {
    flex: 1 1 auto;
    min-height: 29em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.g-hero-text-detail h1 {
    white-space: nowrap;
    color: var(--co-100);
    margin: .5rem 0 calc(var(--padding) * 1.5);
}

.g-hero-text h5 {
    color: var(--co-80);
    font-size: var(--16px);
    font-weight: 700;
}

@media screen and (max-width: 972px) {
    .g-hero.no-img .g-hero-text {
        margin: 3em auto;
    }
}

@media screen and (max-width: 768px) {
    .g-hero {
        flex-flow: column-reverse
    }

    .g-hero-text {
        padding: 0 2em;
    }
}

.g-hero-img.sujet {
    background: url("assets/img/Sujet_blau_Panel_17_2020-red-corrected.jpg") 50% 50% no-repeat;
    background-size: contain;
}

.g-padding-content {
    height: var(--padding-content)
}

.g-card-container {
    padding: 0 var(--padding-content);
}

.g-card {
    padding: calc(var(--padding) * 1.5);
    margin-bottom: var(--padding-double);
    background-image: linear-gradient(135deg, var(--co-10), var(--co-11));
    font-weight: 600;
    letter-spacing: .01em;
    position: relative;
    z-index: 1;
}

.g-card * {
    z-index: 1
}

.g-card:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(135deg, var(--co-11), var(--co-25));
    opacity: 0;
    transition: opacity .5s;
    z-index: -1;
}

.g-card:hover::before {
    opacity: 1;
}

.g-number {
    display: flex;
    align-items: center;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
}

.g-number-integer {
    font-size: 3.4285714286em;
    line-height: 1em;
    margin-right: var(--padding-half);
}

@media screen and (max-width: 768px) {
    .g-number-integer {
        margin-right: .2em;
    }
}

.g-number-date {
    font-size: 1.4285714286em;
}

.g-number-text {
    white-space: nowrap
}

.g-number-description {
    white-space: nowrap;
    margin-bottom: 0.4em;
    opacity: .5;
}

.g-number-unit {
    font-size: var(--16px);
}

.g-number.text-below {
    display: block;
}

.g-number.text-below .g-number-text {
    display: flex;
    align-items: baseline;
}

.g-number app-icon {
    height: 3em;
    width: 3em;
    margin-right: var(--padding-half);
    color: var(--co-55);
}

.g-number.text-below .g-number-integer {
    margin: 0 6px 0 0
}

.right-to-left {
    text-align: right
}

.maintenance {
    position: relative;
    background-color: var(--co-00-transparent);
    width: 100%;
}

.maintenance-bar {
    position: absolute;
    top: 0;
    width: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--co-80);
    border-radius: 0 2px 2px 0;
}

.end-of-life .maintenance-bar {
    background-color: var(--co-primary);
}

app-highchart {
    display: block;
    overflow: visible !important;
    width: 100% !important;
}

.g-chart-container {
    margin-bottom: var(--padding-content)
}

.g-columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.g-columns .gcards {
    margin: 0 var(--padding) var(--padding) 0;
}

.two-columns h3 {
    margin: auto 0;
    letter-spacing: .01em;
}

.g-card.vessel-finder-box {
    box-sizing: border-box;
    padding: 0;
    width: 100%;
}

.two-columns .g-card.vessel-finder-box {
    width: calc(50% - var(--padding));
}

.g-icon-text-horizontal {
    --icon-size: 4em;
    display: flex;
    color: var(--co-55);
    max-height: var(--icon-size);
}

.g-icon-text-horizontal h4 {
    color: var(--co-80)
}

.g-icon-text-horizontal .icon {
    color: var(--co-55);
    height: var(--icon-size);
    width: var(--icon-size);
    margin-right: var(--padding-half);
    transition: color .5s;
}

.g-icon-text-horizontal .text {
    text-transform: uppercase;
    letter-spacing: .2em;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-around;
}

.g-icon-text-horizontal .text b {
    letter-spacing: .02em;
}

.g-icon-text-horizontal h2,
.g-icon-text-horizontal h4 {
    margin: 0
}

.g-icon-text-horizontal.big {
    --icon-size: 6em
}

.g-icon-text-horizontal.big .text {
    justify-content: normal
}

/*STATUS + CONNECTION*/
#gap .status.ALARM h2,
#gap .status h4,
#gap .status .icon,
#gap .status .symbol,
#gap .damper-health .symbol,
#gap .confidence .symbol,
#gap .connection h4,
#gap .connection .icon,
#gap .connection .symbol,
#gap {
    color: var(--currentColor);
}


.status.GOOD {
    --currentColor: var(--green);
}

.status.WARNING {
    --currentColor: var(--yellow);
}

.status.ALARM {
    --currentColor: var(--co-primary);
}

.damper-health.GOOD {
    --currentColor: var(--green);
}

.damper-health.WARNING {
    --currentColor: var(--yellow);
}

.damper-health.ALARM {
    --currentColor: var(--co-primary);
}

.confidence.GOOD {
    --currentColor: var(--green);
}

.confidence.WARNING {
    --currentColor: var(--yellow);
}

.confidence.ALARM {
    --currentColor: var(--co-primary);
}

.connection.strong, .connection.good {
    --currentColor: var(--green);
}

.connection.ok, .connection.weak {
    --currentColor: var(--yellow);
}

.connection.no-signal {
    --currentColor: var(--co-primary);
}

.connection.upload {
    --currentColor: var(--co-80);
}

.mat-mdc-progress-spinner {
    --currentColor: var(--co-80);
    margin: var(--padding-double) 0;
}


/*CONNECTION*/
.good .conn_4 {
    opacity: .3
}

.ok .conn_4 {
    opacity: .3
}

.ok .conn_3 {
    opacity: .3
}

.weak .conn_4 {
    opacity: .3
}

.weak .conn_3 {
    opacity: .3
}

.weak .conn_2 {
    opacity: .3
}

body .mat-mdc-select-panel {
    max-width: inherit;
}

#vesselFinderMap iframe {
    transform: translateY(-20px);
}

.main-container {
    margin-left: 1em;
    margin-right: 1em;
}
