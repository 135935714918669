.highcharts-title, .highcharts-subtitle {
    text-transform: uppercase;
}

.highcharts-tooltip text {
    fill: #F0F0F0;
}

.highcharts-range-selector-buttons text {
    fill: silver;
}

.highcharts-yaxis-grid {
    stroke-width: 1px;
}

.highcharts-axis-labels, .highcharts-axis-title {
    fill: var(--co-55);
}

.highcharts-navigator .highcharts-navigator-handle {
    fill: var(--co-55);
    stroke: #aaa;
}

.highcharts-navigator .highcharts-navigator-outline {
    stroke: #CCC;
}

.highcharts-navigator .highcharts-navigator-xaxis .highcharts-grid-line {
    stroke: var(--co-55);
}

.highcharts-scrollbar .highcharts-scrollbar-rifles {
    stroke: #fff;
}

.highcharts-scrollbar .highcharts-scrollbar-button {
    stroke: var(--co-55);
    fill: var(--co-55);
}

.highcharts-scrollbar .highcharts-scrollbar-arrow {
    fill: #CCC;
}

.highcharts-scrollbar .highcharts-scrollbar-thumb {
    fill: #808083;
    stroke: #808083;
}

.highcharts-contextbutton .highcharts-button-symbol {
    stroke: var(--co-55);
}

/**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
.highcharts-container {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    user-select: none;
    text-transform: uppercase;
    letter-spacing: .03em;
    font-weight: bold;
}

.highcharts-container svg {
    width: 100% !important;
}
.highcharts-root {
    display: block;
    overflow: visible;
    font-family: inherit !important;
}

.highcharts-root text {
    stroke-width: 0;
}

.highcharts-strong {
    font-weight: bold;
}

.highcharts-emphasized {
    font-style: italic;
}

.highcharts-anchor {
    cursor: pointer;
}

.highcharts-background {
    fill: transparent;
}

.highcharts-plot-border, .highcharts-plot-background {
    fill: none;
}

.highcharts-label-box {
    fill: none;
}

.highcharts-button-box {
    fill: inherit;
}

.highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none;
}

.highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0;
}

/* Titles */
.highcharts-title {
    fill: var(--co-100) !important;
    font-size: 1.4285714286em !important;
    letter-spacing: .02em;
}

.highcharts-subtitle {
    fill: var(--co-55);
}

/* Axes */
.highcharts-axis-line {
    fill: none;
    stroke: var(--co-55);
}

.highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-axis-title {
    fill: var(--co-55);
}

.highcharts-axis-labels {
    fill: var(--co-55);
    cursor: default;
    font-size: 0.9em;
}

.highcharts-grid-line {
    fill: none;
    stroke: var(--co-55-transparent);
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-tick {
    stroke: var(--co-55);
}

.highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
}

.highcharts-minor-grid-line {
    stroke: var(--co-55);
}

.highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: var(--co-55);
}

.highcharts-crosshair-category {
    stroke: var(--co-55);
    stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
    display: none;
    cursor: pointer;
    fill: var(--co-55);
    font-size: 0.7em;
    transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
    fill: black;
    font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
    cursor: default;
    white-space: nowrap;
    transition: stroke 150ms;
}
/*g.highcharts-tooltip{display: none;}*/

.highcharts-tooltip text {
    fill: var(--co-90) !important;
    font-size: small;
    transform: translateX(.25em);
}

.highcharts-tooltip .highcharts-header {
    font-size: 0.85em;
}

.highcharts-tooltip-box {
    stroke-width: 1px;
    fill: var(--co-10);
    fill-opacity: 0.85;
}

.highcharts-tooltip-box .highcharts-label-box {
    fill: rgba(0, 0, 0, 0.85);
    fill-opacity: 0.85;
}

div.highcharts-tooltip {
    filter: none;
}

.highcharts-selection-marker {
    fill: #335cad;
    fill-opacity: 0.25;
}

.highcharts-graph {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.highcharts-empty-series {
    stroke-width: 1px;
    fill: none;
    stroke: var(--co-55);
}

.highcharts-state-hover .highcharts-graph {
    stroke-width: 3;
}

.highcharts-point-inactive {
    opacity: 0.2;
    transition: opacity 50ms;
    /* quick in */
}

.highcharts-series-inactive {
    opacity: 0.2;
    transition: opacity 50ms;
    /* quick in */
}

.highcharts-state-hover path {
    transition: stroke-width 50ms;
    /* quick in */
}

.highcharts-state-normal path {
    transition: stroke-width 250ms;
    /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
    transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
    opacity: 0.2;
}

/* Series options */
/* Default colors */
.highcharts-color-0 {
    --active-color: var(--co-100);
    fill: var(--active-color);
    stroke: var(--active-color);
}

.highcharts-color-1 {
    --active-color: var(--co-primary);
    fill: var(--active-color);
    stroke: var(--active-color);
}

.highcharts-color-2 {
    --active-color: var(--co-80);
    fill:  var(--active-color);
    stroke:  var(--active-color);
}

.highcharts-color-3 {
    --active-color: var(--co-55);
    fill: var(--active-color);
    stroke:  var(--active-color);
}

.highcharts-color-4 {
    --active-color: var(--yellow);
    fill: var(--active-color);
    stroke: var(--active-color);
}

.highcharts-color-5 {
    --active-color: var(--green);
    fill: var(--active-color);
    stroke: var(--active-color);
}

.highcharts-color-6 {
    fill: #eeaaee;
    stroke: #eeaaee;
}

.highcharts-color-7 {
    fill: #55BF3B;
    stroke: #55BF3B;
}

.highcharts-color-8 {
    fill: #DF5353;
    stroke: #DF5353;
}

.highcharts-color-9 {
    fill: #7798BF;
    stroke: #7798BF;
}

.highcharts-color-10 {
    fill: #aaeeee;
    stroke: #aaeeee;
}

.highcharts-area {
    fill-opacity: 0.75;
    stroke-width: 0;
}

.highcharts-markers {
    stroke-width: 1px;
    stroke: #2a2a2b;
}

.highcharts-a11y-markers-hidden .highcharts-point:not(.highcharts-point-hover):not(.highcharts-a11y-marker-visible),
.highcharts-a11y-marker-hidden {
    opacity: 0;
}

.highcharts-point {
    stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
    stroke-width: 0;
}

.highcharts-data-label {
    font-size: 0.9em;
    font-weight: bold;
}

.highcharts-data-label-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-data-label text, text.highcharts-data-label {
    fill: #B0B0B3;
}

.highcharts-data-label-connector {
    fill: none;
}

.highcharts-data-label-hidden {
    pointer-events: none;
}

.highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
    fill: var(--co-00);
    stroke-width: 3px;
}

.highcharts-column-series rect.highcharts-point {
    stroke: transparent;
}

.highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #2a2a2b;
}

.highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #2a2a2b;
}

.highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #2a2a2b;
}

.highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: var(--co-55);
    transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
    stroke: #var(--co-55);
    transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
    display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
    fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75;
}

.highcharts-vector-series .highcharts-point {
    fill: none;
    stroke-width: 2px;
}

.highcharts-windbarb-series .highcharts-point {
    fill: none;
    stroke-width: 2px;
}

.highcharts-lollipop-stem {
    stroke: #fff;
}

.highcharts-focus-border {
    fill: none;
    stroke-width: 2px;
}

.highcharts-legend-item-hidden .highcharts-focus-border {
    fill: none !important;
}

/* Legend */
.highcharts-legend-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-legend-item > text {
    fill: var(--co-55);
    font-weight: bold;
    font-size: 1.2em;
    cursor: pointer;
    stroke-width: 0;
}

.highcharts-legend-item:hover text {
    fill: #fff;
}

.highcharts-legend-item-hidden * {
    fill: var(--co-55) !important;
    stroke: var(--co-55) !important;
    transition: fill 250ms;
}

.highcharts-legend-nav-active {
    fill: #F0F0F3;
    cursor: pointer;
}

.highcharts-legend-nav-inactive {
    fill: var(--co-55);
}

circle.highcharts-legend-nav-active, circle.highcharts-legend-nav-inactive {
    /* tracker */
    fill: rgba(192, 192, 192, 0.0001);
}

.highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
}

/* Bubble legend */
.highcharts-bubble-legend-symbol {
    stroke-width: 2;
    fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
    stroke-width: 1;
}

.highcharts-bubble-legend-labels {
    fill: var(--co-55);
}

/* Loading */
.highcharts-loading {
    position: absolute;
    background-color: #2a2a2b;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
}

.highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band, .highcharts-pane {
    fill: #fff;
    fill-opacity: 0.05;
}

.highcharts-plot-line {
    fill: none;
    stroke: var(--co-55);
    stroke-width: 1px;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
    fill: #2a2a2b;
}

.highcharts-boxplot-median {
    stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
    stroke: #fff;
}

.highcharts-gauge-series .highcharts-data-label-box {
    stroke: var(--co-55);
    stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
    fill: #fff;
    stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
    stroke: var(--co-55);
    stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
    transition: fill 250ms, fill-opacity 250ms;
    fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition: fill 50ms, fill-opacity 50ms;
    fill-opacity: 1;
}

.highcharts-venn-series .highcharts-point {
    fill-opacity: 0.75;
    stroke: var(--co-55);
    transition: stroke 250ms, fill-opacity 250ms;
}

.highcharts-venn-series .highcharts-point-hover {
    fill-opacity: 1;
    stroke: var(--co-55);
}

/* Highstock */
.highcharts-navigator-mask-outside {
    fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
    fill: rgba(255, 255, 255, 0.1);
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
}

.highcharts-navigator-outline {
    stroke: var(--co-55);
    fill: none;
}

.highcharts-navigator-handle {
    stroke: var(--co-55);
    fill: var(--co-55);
    cursor: ew-resize;
}

.highcharts-navigator-series {
    fill: #7798BF;
    stroke: #A6C7ED;
}

.highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
    fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: var(--co-25);
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: #var(--co-55);
}

.highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-scrollbar-thumb {
    fill: var(--co-55);
    stroke: var(--co-55);
    stroke-width: 1px;
}

.highcharts-scrollbar-button {
    fill: var(--co-55);
    stroke: var(--co-55);
    stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
    fill: var(--co-55);
}

.highcharts-scrollbar-rifles {
    stroke: var(--co-55);
    stroke-width: 1px;
}

.highcharts-scrollbar-track {
    fill: #404043;
    stroke: #404043;
    stroke-width: 1px;
}

.highcharts-button {
    fill: var(--co-55);
    stroke: var(--co-55);
    cursor: default;
    stroke-width: 1px;
    transition: fill 250ms;
}

.highcharts-button text {
    fill: #ccc;
}

.highcharts-button-hover {
    transition: fill 0ms;
    fill: var(--co-55);
    stroke: var(--co-55);
}

.highcharts-button-hover text {
    fill: #fff;
}

.highcharts-button-pressed {
    font-weight: bold;
    fill: #000003;
    stroke: var(--co-55);
}

.highcharts-button-pressed text {
    fill: #fff;
    font-weight: bold;
}

.highcharts-button-disabled text {
    fill: #ccc;
}

.highcharts-range-selector-buttons .highcharts-button {
    stroke-width: 0;
}

.highcharts-range-label rect {
    fill: none;
}

.highcharts-range-label text {
    fill: var(--co-55);
}

.highcharts-range-input rect {
    fill: none;
}

.highcharts-range-input text {
    fill: silver;
}

.highcharts-range-input {
    stroke-width: 1px;
    stroke: var(--co-55);
}

input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */
}

.highcharts-crosshair-label text {
    fill: #2a2a2b;
    font-size: 1.1em;
}
.highcharts-label{
    pointer-events: none;
}
.highcharts-crosshair-label .highcharts-label-box {
    fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
    stroke: #fff;
    stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
    fill: #2a2a2b;
}

.highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: var(--co-55);
    fill: #2a2a2b;
    transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: #fff;
    fill: var(--co-55);
}

.highcharts-flags-series .highcharts-point text {
    fill: #fff;
    font-size: 0.9em;
    font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
    transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
    stroke: var(--co-55);
}

.highcharts-map-series .highcharts-point-hover {
    transition: fill 0ms, fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
    fill: none;
}

.highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
}

.highcharts-coloraxis {
    stroke-width: 0;
}

.highcharts-coloraxis-marker {
    fill: var(--co-55);
}

.highcharts-null-point {
    fill: #f7f7f7;
}

/* 3d charts */
.highcharts-3d-frame {
    fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
    fill: var(--co-55);
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;
}

.highcharts-contextbutton:hover {
    fill: var(--co-55);
    stroke: var(--co-55);
}

.highcharts-button-symbol {
    stroke: var(--co-55);
    stroke-width: 3px;
}

.highcharts-menu {
    border: 1px solid var(--co-55);
    background: #2a2a2b;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: var(--co-55);
    cursor: pointer;
    transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
    background: #335cad;
    color: #2a2a2b;
}

/* Drilldown module */
.highcharts-drilldown-point {
    cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: #F0F0F3;
    font-weight: bold;
    text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
    font-weight: bold;
    font-size: 12px;
    fill: var(--co-55);
}

/* Drag-panes module */
.highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
    stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: var(--co-55);
}

.highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: var(--co-55);
}

/* Annotations module */
.highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: #fff;
    fill: #fff;
    fill-opacity: 0.75;
}

.highcharts-annotation-label text {
    fill: var(--co-55);
    font-size: small;
}

/* Gantt */
.highcharts-treegrid-node-collapsed, .highcharts-treegrid-node-expanded {
    cursor: pointer;
}

.highcharts-point-connecting-path {
    fill: none;
}

.highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px;
}
.highcharts-plot-band-label {fill: var(--co-80)}

#gradientWhiteTransparent stop {
    stop-color: var(--co-100);
}
#gradientWhiteTransparent stop[offset="0"] {
    stop-opacity: .75;
}
#gradientWhiteTransparent stop[offset="1"] {
    stop-opacity: 0;
}
#gradient-0 stop {
    stop-color: var(--co-100);
}
#gradient-0 stop[offset="0"] {
    stop-opacity: 1;
}
#gradient-0 stop[offset="1"] {
    stop-opacity: .5;
}
#gradient-1 stop {
    stop-color: var(--co-primary);
}
#gradient-1 stop[offset="0"] {
    stop-opacity: .5;
}
#gradient-1 stop[offset="1"] {
    stop-opacity: 1;
}
.highcharts-plot-background {
    /*fill: url(#gradient-1);*/
}
.highcharts-color-0 .highcharts-area {
    fill-opacity: 1;
    fill: url(#gradientWhiteTransparent);
}

.sparkline .highcharts-grid-line,
.sparkline .highcharts-tick,
.no-grid-line .highcharts-grid-line{stroke: transparent;}
.sparkline .highcharts-axis-line{stroke: transparent;}

.highcharts-column-series .highcharts-point {fill: url(#gradient-0);}
.highcharts-column-series .highcharts-negative {fill: url(#gradient-1);}

g.alarm-annotation {
    /*pointer-events: none;*/
}
.alarm-annotation .highcharts-annotation-label-box{
    stroke: var(--co-primary);
    fill: var(--co-00);
    fill-opacity: .9;
}
.warning-annotation {
    --currentColor: var(--yellow);
    color: var(--currentColor);
}
.warning-annotation .highcharts-annotation-label-box{
    stroke: var(--currentColor);
    fill: var(--co-00);
    fill-opacity: .9;
}
div.alarm-annotation {
    cursor: pointer;
    pointer-events: all;
}
.alarm-annotation a {
    color: var(--co-primary);
    text-decoration: none;
}
.warning-annotation a {
    color: var(--yellow);
    text-decoration: none;
}

.highcharts-fixed {display: none}
.info text {fill: var(--co-80)}

.highcharts-tooltip span{background-color: var(--co-00)}

.app-chart-status .highcharts-series-group{}
.chart:hover .highcharts-point{fill-opacity: 1}

.app-chart-status .highcharts-point{
    stroke-width: 2px;
    stroke: var(--active-color);
    fill-opacity: 0;
    transition: fill-opacity .5s;
}
.app-chart-status .highcharts-pie-series .highcharts-color-0 {--active-color: var(--green);}
.app-chart-status .highcharts-pie-series .highcharts-color-1 {--active-color: var(--yellow);}
.app-chart-status .highcharts-pie-series .highcharts-color-2 {--active-color: var(--co-primary);}
.app-chart-status .highcharts-pie-series .highcharts-color-3 {}


.highcharts-pie-series.highcharts-tracker .highcharts-point{}
