@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$gap-red: (
  50: #ed1c24,
  100: #ed1c24,
  200: #ed1c24,
  300: #ed1c24,
  400: #ed1c24,
  500: #ed1c24,
  600: #ed1c24,
  700: #ed1c24,
  800: #ed1c24,
  900: #ed1c24,
  A100: #ed1c24,
  A200: #ed1c24,
  A400: #ed1c24,
  A700: #ed1c24,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);
$gap-color: (
  50: #ffffff,
  100: #dee8ed,
  200: #b1c0c9,
  300: #717e8b,
  400: #283440,
  500: #283440,
  600: #283440,
  700: #0f1416,
  800: #0f1416,
  900: #000000,
  A100: #283440,
  A200: #283440,
  A400: #283440,
  A700: #283440,
  contrast: (
    50: #283440,
    100: #283440,
    200: #0f1416,
    300: #000000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #dee8ed,
    800: #dee8ed,
    900: #b1c0c9,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);

$red-palette: (
  0: #ed1c24,
  10: #ed1c24,
  20: #ed1c24,
  25: #ed1c24,
  30: #ed1c24,
  35: #ed1c24,
  40: #ed1c24,
  50: #ed1c24,
  60: #ed1c24,
  70: #ed1c24,
  80: #ed1c24,
  90: #ed1c24,
  95: #ed1c24,
  98: #ed1c24,
  99: #ed1c24,
  100: #ed1c24,
  secondary: (
    0: #ed1c24,
    10: #ed1c24,
    20: #ed1c24,
    25: #ed1c24,
    30: #ed1c24,
    35: #ed1c24,
    40: #ed1c24,
    50: #ed1c24,
    60: #ed1c24,
    70: #ed1c24,
    80: #ed1c24,
    90: #ed1c24,
    95: #ed1c24,
    98: #ed1c24,
    99: #ed1c24,
    100: #ed1c24,
  ),
  neutral: (
    0: #000000,
    10: #201a19,
    20: #362f2e,
    25: #413a39,
    30: #4d4544,
    35: #59504f,
    40: #655c5b,
    50: #7f7574,
    60: #998e8d,
    70: #b4a9a7,
    80: #d0c4c2,
    90: #ede0de,
    95: #fbeeec,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral-variant: (
    0: #000000,
    10: #201a19,
    20: #362f2e,
    25: #413a39,
    30: #4d4544,
    35: #59504f,
    40: #655c5b,
    50: #7f7574,
    60: #998e8d,
    70: #b4a9a7,
    80: #d0c4c2,
    90: #ede0de,
    95: #fbeeec,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #ed1c24,
    10: #ed1c24,
    20: #ed1c24,
    25: #ed1c24,
    30: #ed1c24,
    35: #ed1c24,
    40: #ed1c24,
    50: #ed1c24,
    60: #ed1c24,
    70: #ed1c24,
    80: #ed1c24,
    90: #ed1c24,
    95: #ed1c24,
    98: #ed1c24,
    99: #ed1c24,
    100: #ed1c24,
  ),
);

$mdg-app-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: $red-palette,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($mdg-app-theme);
}

@font-face {
  font-family: DIN Next;
  src: url('/src/assets/Font/DIN-Light.woff2') format('woff2');
  src: url('/assets/Font/DIN-Light.woff') format('woff');
  src: url('/assets/Font/DIN-Light.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: DIN Next;
  src: url('/src/assets/Font/DIN-Regular.woff2') format('woff2');
  src: url('/assets/Font/DIN-Regular.woff') format('woff');
  src: url('/assets/Font/DIN-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: DIN Next;
  src: url('/src/assets/Font/DIN-Bold.woff2') format('woff2');
  src: url('/assets/Font/DIN-Bold.woff') format('woff');
  src: url('/assets/Font/DIN-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Frutiger;
  src: url('/src/assets/Font/FrutigerLTStd-Light.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: Frutiger;
  src: url('/src/assets/Font/FrutigerLTStd-Roman.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Frutiger;
  src: url('/src/assets/Font/FrutigerLTStd-Bold.otf') format('opentype');
  font-weight: 600;
}

:root {
  font-size: 14px;
  --16px: 1.1428571429em;
  --co-primary: #ed1c24;
  --co-00: #000;
  --co-00-transparent: rgba(0, 0, 0, 0.5);
  --co-10: #0f1416;
  --co-10-transparent: rgba(15, 20, 22, 0.5);
  --co-11: #13191c;
  --co-25: #283440;
  --co-25-transparent: rgba(40, 52, 64, 0.5);
  --co-55: #717e8b;
  --co-55-transparent: rgba(113, 126, 139, 0.5);
  --co-80: #b1c0c9;
  --co-90: #dee8ed;
  --co-100: #fff;
  --padding: 1.7142857143rem;
  --padding-half: calc(var(--padding) / 2);
  --padding-double: calc(var(--padding) * 2);
  --padding-content: 6rem;
  --green: #7fd421;
  --yellow: #eecb1b;
  --green: #8dae68;
  --yellow: #d7b147;
  --currentColor: var(--co-90);
  --top-bar-height: 50px;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fff;
}

html {
  font-size: 14px;
}

body {
  color: var(--co-100);
  background-color: var(--co-00);
  font-family: DIN Next, Helvetica, Arial, sans-serif;
  margin: 0;
}

a {
  color: var(--co-primary);
}

@media screen and (max-width: 972px) {
  body {
    //font-size: calc((100vw - 400px) / (1460 - 400) / (14 - 11) + 11px);
    //font-size: 0.8rem;
    --padding: 2.4vw;
    --padding-half: calc(var(--padding) / 2);
    --padding-double: calc(var(--padding) * 2);
    --padding-content: 1em;
  }
}

@media screen and (max-width: 858px) {
  body {
    --padding: 1em;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 0.8em;
  }
}

.logo {
  position: relative;
  display: inline-block;
}

p {
  font-size: 1.2857142857em;
  font-size: var(--16px);
  font-weight: 200;
  line-height: 1.4;
  color: var(--active-color);
  --active-color: var(--co-80);
  font-family: Frutiger, Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 4.5714285714em;
  line-height: 1;
  letter-spacing: 0.02em;
}

h2 {
  font-size: 3.4285714286em;
  line-height: 1.25;
  letter-spacing: 0.01em;
}

h3 {
  font-size: 2.7142857143em;
  line-height: 1.2;
}

h4 {
  font-size: 1.4285714286em;
  line-height: 1;
  letter-spacing: 0.02em;
}

h5 {
  font-size: 1.7142857143em;
  line-height: 1.33em;
  font-weight: 400;
}

h6 {
  font-size: 1em;
  line-height: 1.2;
  letter-spacing: 0.2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: auto;
}

h1,
h2,
h3,
h4,
h6 {
  text-transform: uppercase;
  font-family: DIN Next, Helvetica, Arial, sans-serif;
  color: var(--active-color);
  --active-color: var(--co-90);
}

ul {
  padding-inline-start: var(--padding-half);
}

ul li {
  list-style-type: '■';
  padding-inline-start: var(--padding-half);
}

ul li::marker {
  color: var(--co-primary);
}

.mat-mdc-dialog-container,
.mat-mdc-card,
.mat-mdc-card-outlined {
  --mdc-outlined-card-outline-color: var(--co-00);
  --mdc-outlined-card-container-color: var(--co-00);
  background-color: var(--co-00);
  background-image: linear-gradient(135deg, var(--co-10-transparent), var(--co-10));
  border-radius: 0 !important;
  padding: var(--padding) !important;
  color: var(--co-100);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-header-pagination-chevron {
  border-color: var(--co-100);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-ripple {
  background-color: var(--co-100);
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-container .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--co-100);
}

.mat-mdc-select {
  font-size: 14px !important;
}

.mat-mdc-option {
  --mat-option-label-text-size: 14px;
  --mat-option-selected-state-layer-color: var(--co-25-transparent);

  &.mdc-list-item--disabled .mdc-list-item__primary-text,
  &.mdc-list-item--disabled .mat-pseudo-checkbox {
    opacity: 0.5 !important;
  }
}

.mat-mdc-dialog-container {
  background-color: var(--co-25);
  box-shadow: 0 11px 15px -7px rgb(0 0 0), 0px 24px 38px 3px rgb(0 0 0 / 80%), 0px 9px 46px 8px rgb(0 0 0 / 60%);
}

.mat-mdc-card-subtitle,
.mat-mdc-card-content {
  font-size: 1rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-subscript-wrapper {
  font-size: 1rem;
}

.mat-form-field-appearance-fill .mat-mdc-form-field-flex,
.mat-form-field-appearance-fill .mdc-text-field--filled {
  background: none;
  padding: 0;
  --mdc-filled-text-field-container-color: none;
  --mdc-filled-text-field-disabled-container-color: none;
  --mdc-filled-text-field-disabled-input-text-color: var(--co-100);
  --mdc-filled-text-field-disabled-label-text-color: var(--co-100);
  font-size: 14px;

  &.mdc-text-field--disabled {
    opacity: 0.5;
  }

  .mdc-floating-label:not(.mdc-floating-label--float-above) {
    transform: none;
  }

  .mat-mdc-form-field-focus-overlay,
  .mat-mdc-text-field-wrapper:hover {
    --mat-form-field-focus-state-layer-opacity: 0;
    background-color: transparent !important;
  }
}

.mat-form-field-appearance-fill .mat-mdc-form-field-error-wrapper {
  padding: 0;

  .mat-mdc-form-field-error {
    display: inline-flex;
    font-size: 12px;
    line-height: 12px;
    padding-top: 0.25rem;
  }
}

.mat-toolbar {
  --mat-toolbar-container-background-color: var(--co-10);
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: auto;
}

.mat-mdc-select-panel,
.mat-mdc-table {
  font-family: inherit;
  --active-color: var(--co-80);
  background-color: var(--co-10);
}

body div .mat-row,
body div .mat-mdc-row,
body div .mat-footer-row,
body div .mat-mdc-footer-row {
  height: calc(var(--padding) * 3) !important;
}

body div .mat-mdc-cell:first-of-type,
body div .mat-mdc-header-cell:first-of-type,
body div .mat-mdc-footer-cell:first-of-type {
  padding-left: var(--padding-double);
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  border-bottom-color: var(--co-00-transparent);

  .mat-mdc-select {
    color: var(--active-color) !important;
  }
}

.mat-row,
.mat-mdc-row {
  background: transparent;
}
.mat-mdc-no-data-row .mat-cell:first-of-type {
  padding-left: var(--padding-double);
  vertical-align: middle;
}
.mat-cell,
.mat-mdc-cell {
  background: transparent !important;
}

.mat-cell,
.mat-footer-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
  color: var(--active-color);
}

.mat-mdc-header-row {
  --mat-table-header-headline-color: var(--co-80);
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-line-height: 12px;
}

mat-checkbox.mat-mdc-checkbox .mdc-checkbox__background {
  --mdc-checkbox-selected-icon-color: var(--currentColor) !important;
  --mdc-checkbox-selected-focus-icon-color: var(--currentColor) !important;
  --mdc-checkbox-selected-hover-icon-color: var(--currentColor) !important;

  .mdc-checkbox__checkmark-path {
    stroke: var(--co-00) !important;
  }
}

.mat-mdc-select-arrow-wrapper {
  transform: none !important;
}

.mat-mdc-header-cell {
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 0.1em;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  background-color: var(--co-primary);
}

.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-raised-button.mat-accent,
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-container-color: var(--co-25);
}

mat-form-field {
  display: block;
}

mat-slide-toggle {
  margin: 0 var(--padding) var(--padding-half) var(--padding);
  --mdc-switch-selected-focus-state-layer-color: var(--co-primary) !important;
  --mdc-switch-selected-handle-color: var(--co-primary) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--co-primary) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--co-primary) !important;
  --mdc-switch-selected-focus-handle-color: var(--co-primary) !important;
  --mdc-switch-selected-hover-handle-color: var(--co-primary) !important;
  --mdc-switch-selected-pressed-handle-color: var(--co-primary) !important;
  --mdc-switch-selected-focus-track-color: #ed1d234d !important;
  --mdc-switch-selected-hover-track-color: #ed1d234d !important;
  --mdc-switch-selected-pressed-track-color: #ed1d234d !important;
  --mdc-switch-selected-track-color: #ed1d234d !important;
  --mat-switch-selected-track-outline-color: var(--co-55) !important;
  --mat-switch-unselected-track-outline-color: var(--co-55) !important;

  .mdc-form-field > label {
    padding-left: 0.5rem;
    font-size: 14px;
  }
}

.mat-mdc-progress-spinner {
  left: 50%;
  transform: translatex(-50%);
}

.mat-mdc-tab-group {
  --mat-tab-header-active-label-text-color: var(--co-100) !important;
  --mat-tab-header-active-focus-label-text-color: var(--co-100) !important;

  .mdc-tab__content .mat-icon {
    padding-right: var(--padding-half);
  }
}

.mat-calendar .mat-calendar-body-selected {
  --mat-datepicker-calendar-date-selected-state-text-color: #ede0de;
}

.g-high-contrast {
  --co-55: #b1c0c9;
  --co-80: #fff;
  --co-90: #fff;
  --co-100: #fff;
}

// --------------------------
// ------- LIGHT MODE -------
// --------------------------
.g-light-mode {
  --co-00: #fff;
  --co-00-transparent: rgba(255, 255, 255, 0.3);
  --co-10: hsl(200, 29%, 95%);
  --co-10-transparent: hsla(200, 29%, 95% 0.3);
  --co-11: hsl(201, 28%, 93%);
  --co-25: hsl(203, 18%, 87%);
  --co-25-transparent: hsla(203, 18%, 74%, 0.3);
  --co-55: hsl(210, 10%, 67%); //#5c6670 #8c96a1;
  --co-55-transparent: hsla(211, 10%, 40%, 0.1);
  --co-80: #283440;
  --co-90: #0f1416;
  --co-100: #000;
  --currentColor: var(--co-90);
  --yellow: #a27f00;
  background-color: var(--co-00);

  .logo:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    max-width: 173px;
    background-image: url(assets/geislinger-logo-dark.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .damper-card .logo:after {
    content: unset;
  }

  .logo img {
    opacity: 0;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-tab-label,
  .mat-tab-link,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: var(--co-80);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: var(--co-80);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-frame {
    border-color: var(--co-55);
  }

  mat-checkbox.mat-mdc-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--co-25);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right, rgba(#000, 0.7) 0%, rgba(#000, 0.7) 33%, transparent 0%);
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-tab .mdc-tab__text-label {
    --mat-tab-header-inactive-label-text-color: var(--co-100);
    --mat-tab-header-active-hover-label-text-color: var(--co-100);
    --mat-tab-header-inactive-hover-label-text-color: var(--co-100);
  }

  .mat-mdc-form-field {
    --mdc-filled-text-field-input-text-color: var(--co-100);
    --mdc-filled-text-field-label-text-color: var(--co-80);
    --mdc-filled-text-field-hover-label-text-color: var(--co-80);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-standard .mat-form-field-underline {
    background-color: rgba(#000, 0.7);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: rgba(#000, 0.5);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.7);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow {
    color: rgba(0, 0, 0, 0.7);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 0.5);
  }

  mat-slide-toggle {
    --mdc-switch-unselected-focus-track-color: var(--co-25) !important;
    --mdc-switch-unselected-hover-track-color: var(--co-25) !important;
    --mdc-switch-unselected-pressed-track-color: var(--co-25) !important;
    --mdc-switch-unselected-track-color: var(--co-25) !important;
    --mat-switch-unselected-track-outline-color: var(--co-55) !important;
    --mat-switch-selected-track-outline-color: var(--co-55) !important;

    .mdc-form-field {
      --mat-switch-label-text-color: var(--co-100);
    }

    .mdc-switch:enabled .mdc-switch__track::before {
      background-color: var(--co-25);
    }
  }

  mat-checkbox {
    .mdc-form-field {
      --mat-checkbox-label-text-color: var(--co-100);
      --mdc-checkbox-selected-checkmark-color: var(--co-00);
    }
  }

  .mat-mdc-card {
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  }

  .mat-mdc-table {
    background-color: var(--co-10);
  }

  .mat-mdc-select {
    --mat-select-enabled-trigger-text-color: var(--co-100);
  }

  .mat-mdc-paginator {
    .mat-mdc-select {
      --mat-select-enabled-trigger-text-color: var(--co-00);
    }
  }

  .mat-mdc-icon-button {
    --mdc-icon-button-icon-color: var(--co-80);
  }

  .mat-mdc-raised-button {
    --mdc-protected-button-container-color: var(--co-80);
  }
}
